import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { AppContext } from '../contexts'

const MetaData = () => {
  const { appContext } = useContext(AppContext)
  const { data } = appContext

  const title = data?.MetaTitle || data?.Name || 'Wellby'
  const description = data?.MetaDescription || ''
  const keywords = data?.MetaKeywords || ''
  let robots = data?.MetaRobots || 'index, follow'

  if (process.env.NODE_ENV !== 'production')
  {
    robots = 'noindex, nofollow'
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={robots} />
    </Helmet>
  )
}

export default React.memo(MetaData)
