import React from 'react'
import Checkmark from 'SVGs/checkmark.svg'
import { addWebPToImageURL } from 'Helpers/utilities'

const PromotionContainerCardBlock = ({ data, inEditMode }) => {
  const { BulletIcon, Content } = data

  return (
    <div className="flex font-display">
      <div className='min-w-[24px] max-w-[24px] h-6 text-violet mr-2'>
        {
          BulletIcon
            ? <img data-src={addWebPToImageURL(BulletIcon?.Url)} alt={BulletIcon?.AltTag} className="lazy w-full h-full" />
            : <Checkmark className="w-full h-full" />
        }
      </div>
      <div dangerouslySetInnerHTML={{ __html: Content }}></div>
    </div>
  )
}

export default React.memo(PromotionContainerCardBlock)
