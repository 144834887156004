import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { addWebPToImageURL } from 'Helpers/utilities'

const BlogCtaBlock = ({ data, inEditMode }) => {
  const { Heading, Image, Content, CtaButton } = data
  return (
    <div className="container mx-auto max-w-screen-md">
      <div className="md:px-4 px-0 lg:py-8 md:py-6 py-4">
        <div className="grid md:grid-cols-12 gap-6 rounded-lg py-6 px-8" style={{ backgroundColor: '#ebf7fd' }}>
          <div className="hidden md:block md:col-span-6 relative mx-2">
            <img data-epi-edit="Image" data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy w-full absolute top-1/2 transform -translate-y-1/2 rounded-md" />
          </div>
          <div className="md:col-span-6 grid md:gap-4 gap-2 md:py-4">
            <img data-epi-edit="Image" data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy justify-self-center w-3/4 rounded-md md:hidden" />
            <h3 className="lg:text-2xl text-lg font-display font-medium tracking-tight text-center md:text-left" data-epi-edit="Heading" dangerouslySetInnerHTML={{ __html: Heading }} />
            <div className="text-violet-bright font-body md:text-lg text-center md:text-left" data-epi-edit="Content" dangerouslySetInnerHTML={{ __html: Content }} />
            <p className="text-center md:text-left">
              <RoundedButtonBlock data={CtaButton} name="CtaButton" inEditMode={inEditMode} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(BlogCtaBlock)
