import React, { useEffect, useState, useRef } from 'react'
import { addWebPToImageURL } from 'Helpers/utilities'

const ExitSiteSettingsBlock = ({ data, externalLinkToOpen, isOpen, onClose }) => {
  if (!data) return null
  const [open, setOpen] = useState(isOpen)
  const modalRef = React.useRef()

  useEffect(() => {
    // prevent scrolling
    document.body.style.overflow = 'hidden'

    // focus on first element
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    const firstElement = focusableModalElements[0];
    firstElement.focus();

    window.addEventListener('keydown', downHandler)
    // remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  function downHandler (e) {
    // close modal on escape key
    if (e.keyCode === 27) {
      closeModal()
    }

    // trap focus within modal
    if (e.key === 'Tab') {
      const focusableModalElements = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[focusableModalElements.length - 1];

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      }
    }
  }

  const closeModal = () => {
    document.body.style.overflow = 'unset'
    setOpen(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  if (!open) return null

  return (
    <div style={{ zIndex: 10000000000 }} className="fixed inset-0 overflow-y-auto backdrop-blur backdrop-filter" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-2">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => closeModal()}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} className="inline-block p-8 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all
                        my-8 sm:align-middle lg:max-w-screen-md sm:max-w-screen-md w-full font-display">
          <div><img data-src={addWebPToImageURL(data.LogoImage?.Url)} alt={data.LogoImage?.AltTag} className='lazy max-h-[40px] md:max-h-[180px]' /></div>
          <div className='text-xl font-medium mb-4 mt-6'>{data.HeaderTitle}</div>
          <div dangerouslySetInnerHTML={{ __html: data.Content }}></div>
          <div className='flex flex-col justify-center gap-4 mt-4 md:flex-row md:gap-8'>
            <a href={externalLinkToOpen} target='_blank' className='flex justify-center items-center bg-violet text-white font-medium text-center w-full px-4 py-2 rounded-full'>Continue</a>
                      <button className='bg-white text-violet font-medium border-3 border-violet w-full px-4 py-2 rounded-full' onClick={() => closeModal()}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default React.memo(ExitSiteSettingsBlock);
