import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { addWebPToImageURL } from 'Helpers/utilities'

const HighlightedTipsCardBlock = ({ data, inEditMode, isActive }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { Image, Heading, Content, Button } = data
  const markdown = { __html: Content }

  return (
    <div className={`${isActive ? 'border-violet shadow-active' : 'shadow-normal'}
      bg-white border border-gray-100 cursor-pointer rounded-lg md:p-8 md:px-12 p-4 grid content-between md:gap-4 gap-3`}>
      <div className="grid md:gap-4 gap-2">
        <div data-epi-edit="Image" className="grid items-center">
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="45" height="45"/>
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="25" height="25"/>
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
        <h5 data-epi-edit="Heading" className="font-display font-medium lg:text-lg md:text-base text-xs">
          {Heading}
        </h5>
        <div data-epi-edit="Content" className="font-body md:text-sm text-xxs" dangerouslySetInnerHTML={markdown} />
      </div>
      <RoundedButtonBlock data={Button} name='Button' inEditMode={inEditMode} />
    </div>
  )
}

export default React.memo(HighlightedTipsCardBlock)
