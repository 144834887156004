import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const CtaContentBlock = ({ data, inEditMode, handleClick }) => {
  const { Content, CtaLink, Heading, Image } = data
  const { Href, Text, Target } = CtaLink
  return (
    <div className="flex justify-center items-center">
      <div className="bg-white rounded-md shadow-active md:flex grid card">
        {Image && <img className="lazy md:w-1/2 md:h-full w-full md:rounded-l-md md:rounded-tr-none rounded-t-md" data-src={addWebPToImageURL(Image.Url)} alt={Image.AltText} />}
        <div className="w-full flex flex-col">
          <div className="p-4 pb-0 flex-1">
            <h3 className="lg:text-xl md:text-lg text-base lg:leading-6 md:leading-5 font-display font-medium mb-1">{Heading}</h3>
            <div className="flex items-center mt-4 font-body" dangerouslySetInnerHTML={{ __html: Content }} />
          </div>
          <span onClick={(e) => handleClick(e)}>
          <RouterLinkTemplate Path={Href} Target={Target} inEditMode={inEditMode}>
            <div className="p-4 flex items-center justify-between cursor-pointer text-violet hover:text-violet-bright font-medium">
              {Text}
            </div>
          </RouterLinkTemplate>
          </span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CtaContentBlock)
