import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { getMonthName, getDateWithFormat, formatAMPM } from 'Helpers/utilities'
import { addWebPToImageURL } from 'Helpers/utilities'

const DateTimeRenderer = ({ start, startName, end, endName }) => {
  if (start === end && start.getHours() === 0 && start.getMinutes() === 0) {
    return (
      <>
        <span data-epi-edit={startName}>
          {getDateWithFormat(start)}
        </span>
        <span data-epi-edit={endName}> </span>
      </>
    )
  }

  if (start === end) {
    return (
      <>
        <span data-epi-edit={startName}>
          {formatAMPM(start)} on {getMonthName(start)} {start.getDate()},{' '} {start.getFullYear()}
        </span>
        <span data-epi-edit={endName}> </span>
      </>
    )
  }

  if (
    start.getDate() === end.getDate() &&
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  ) {
    return (
      <span>
        <span data-epi-edit={startName}>{formatAMPM(start)}</span> - <span data-epi-edit={endName}>{formatAMPM(end)}</span> on {getDateWithFormat(start)}
      </span>
    )
  }

  return (
    <>
      <span data-epi-edit={startName}>{start.getFullYear() === end.getFullYear() ? `${getMonthName(start)} ${start.getDate()}` : getDateWithFormat(start)}</span> - <span data-epi-edit={endName}>{getDateWithFormat(end)}</span>
    </>
  )
}

const CommunityEventBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }
  const { Image, ImageLocation, Heading, Subheading, Button, Heading1, StartDate, EndDate, LinkText } = data
  const startDate = new Date(StartDate)
  const endDate = EndDate ? new Date(EndDate) : startDate
  const dateFormat = (date) => date.toISOString().replace(/-|:|\.\d\d\d/g, '')
  const hrefString = `http://www.google.com/calendar/render?action=TEMPLATE&text=${Heading}&dates=${dateFormat(startDate)}/${dateFormat(endDate)}&details=${Subheading}`

  const ImageContent = ({}) => {
    return (
      <div>
          <div data-epi-edit="Image">
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="1200" height="500" />
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="400" height="300" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
      </div>
    )
  }

  const Content = () => {
    let contentContainerClass = 'xl:pl-12 lg:pl-10 md:pl-8 sm:pl-6 pl-3'
    if (ImageLocation && ImageLocation === 'right') {
      contentContainerClass = 'xl:pr-12 lg:pr-10 md:pr-8 sm:pr-6 pr-3'
    }
    return (
      <div className={`${contentContainerClass}`}>
        <h3 className="xl:text-2xl lg:text-xl md:text-lg sm:text-base text-xs font-medium font-display ">
          <span data-epi-edit="Heading"> {Heading} {inEditMode && !Heading && 'Click to edit Heading'} </span>
        </h3>
        <h5 className="xl:text-lg lg:text-base md:text-sm sm:text-xs text-xxs xl:my-5 lg:my-4 md:my-3 sm:my-2 my-1">
          <span data-epi-edit="Subheading"> <p>{Subheading}</p> {inEditMode && !Subheading && 'Click to edit Subheading'}</span>
        </h5>
        <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
        <div className="xl:mt-10 lg:mt-8 sm:mt-4 mt-2">
          <h2 className="font-medium xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs">
            <span data-epi-edit="Heading1"> {Heading1} {inEditMode && !Heading1 && 'Click to edit Heading'}</span>
          </h2>
          <h5 className="xl:text-xl lg:text-lg md:text-xs sm:text-xs text-xxs xl:my-5 lg:my-4 sm:my-2 mt-1 ">
            {<DateTimeRenderer start={startDate} startName="StartDate" end={endDate} endName="EndDate" />}
          </h5>
            <a className="xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs font-medium text-violet"
              data-epi-edit="LinkText" suppressHydrationWarning href={hrefString} target="_blank" rel="noreferrer">
              {LinkText || 'Add to Calendar'} {inEditMode && !LinkText && 'Click to edit link text'}
            </a>
        </div>
      </div>
    )
  }

  const ImageContentAlignment = () => {
      if (ImageLocation === 'right') {
        return (
          <>
            <Content/>
            <ImageContent/>
          </>
        )
      }

      return (
        <>
          <ImageContent/>
          <Content/>
        </>
      )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-36 lg:mx-28 md:mx-20 sm:mx-8 mx-6 lg:py-16 md:py-12 py-8">
        <div className="flex ">
          <ImageContentAlignment/>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CommunityEventBlock)
