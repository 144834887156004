import { useEffect } from 'react'

export default function useOutsideClick (ref, action) {
  useEffect(() => {
    const eventHandler = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        action()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', eventHandler)
    document.addEventListener('touchstart', eventHandler)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', eventHandler)
      document.removeEventListener('touchstart', eventHandler)
    }
  }, [ref])
}
